

export const sliderImage = [
  {
    title: "First Slide",
    description: "This is the first slider Image of our carousel",
    urls: 'https://www.valueprint.com.sg/wp-content/uploads/2020/09/new2-1.jpg',
  },
  {
    title: "Second Slide",
    description: "This is the second slider Image of our carousel",
    urls: 'https://www.valueprint.com.sg/wp-content/uploads/2020/09/new1-1.jpg',
  },
  {
    title: "Third Slide",
    description: "This is the Third slider Image of our carousel",
    urls: 'https://www.valueprint.com.sg/wp-content/uploads/2020/09/banner-1.jpg',
  },

];