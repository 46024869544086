export const Paypal = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" id="paypal">
      <path
        fill="#139AD6"
        d="M49.2 28.2h-3.4c-.2 0-.4.2-.5.4l-1.4 8.8c0 .2.1.3.3.3H46c.2 0 .3-.1.3-.3l.4-2.5c0-.2.2-.4.5-.4h1.1c2.3 0 3.6-1.1 3.9-3.3.2-.9 0-1.7-.4-2.2-.6-.5-1.5-.8-2.6-.8m.4 3.3c-.2 1.2-1.1 1.2-2 1.2H47l.4-2.3c0-.1.1-.2.3-.2h.2c.6 0 1.2 0 1.5.4.2.1.2.4.2.9"
      ></path>
      <path
        fill="#263B80"
        d="M24.7 28.2h-3.4c-.2 0-.4.2-.5.4l-1.4 8.8c0 .2.1.3.3.3h1.6c.2 0 .4-.2.5-.4l.4-2.4c0-.2.2-.4.5-.4h1.1c2.3 0 3.6-1.1 3.9-3.3.2-.9 0-1.7-.4-2.2-.6-.5-1.4-.8-2.6-.8m.4 3.3c-.2 1.2-1.1 1.2-2 1.2h-.5l.4-2.3c0-.1.1-.2.3-.2h.2c.6 0 1.2 0 1.5.4.1.1.2.4.1.9M35 31.4h-1.6c-.1 0-.3.1-.3.2l-.1.5-.1-.2c-.4-.5-1.1-.7-1.9-.7-1.8 0-3.4 1.4-3.7 3.3-.2 1 .1 1.9.6 2.5.5.6 1.2.8 2.1.8 1.5 0 2.3-.9 2.3-.9l-.1.5c0 .2.1.3.3.3H34c.2 0 .4-.2.5-.4l.9-5.6c-.1-.1-.3-.3-.4-.3m-2.3 3.2c-.2.9-.9 1.6-1.9 1.6-.5 0-.9-.2-1.1-.4-.2-.3-.3-.7-.3-1.2.1-.9.9-1.6 1.8-1.6.5 0 .8.2 1.1.4.3.3.4.8.4 1.2"
      ></path>
      <path
        fill="#139AD6"
        d="M59.4 31.4h-1.6c-.1 0-.3.1-.3.2l-.1.5-.1-.2c-.4-.5-1.1-.7-1.9-.7-1.8 0-3.4 1.4-3.7 3.3-.2 1 .1 1.9.6 2.5.5.6 1.2.8 2.1.8 1.5 0 2.3-.9 2.3-.9l-.1.5c0 .2.1.3.3.3h1.5c.2 0 .4-.2.5-.4l.9-5.6c-.1-.1-.2-.3-.4-.3m-2.3 3.2c-.2.9-.9 1.6-1.9 1.6-.5 0-.9-.2-1.1-.4-.2-.3-.3-.7-.3-1.2.1-.9.9-1.6 1.8-1.6.5 0 .8.2 1.1.4.4.3.5.8.4 1.2"
      ></path>
      <path
        fill="#263B80"
        d="M43.7 31.4H42c-.2 0-.3.1-.4.2L39.4 35l-1-3.2c-.1-.2-.2-.3-.5-.3h-1.6c-.2 0-.3.2-.3.4l1.8 5.3-1.7 2.4c-.1.2 0 .5.2.5h1.6c.2 0 .3-.1.4-.2l5.5-7.9c.3-.3.1-.6-.1-.6"
      ></path>
      <path
        fill="#139AD6"
        d="m61.3 28.5-1.4 9c0 .2.1.3.3.3h1.4c.2 0 .4-.2.5-.4l1.4-8.8c0-.2-.1-.3-.3-.3h-1.6c-.1-.1-.2 0-.3.2"
      ></path>
      <path
        fill="#263B80"
        d="M12 25.2c-.7-.8-2-1.2-3.8-1.2h-5c-.3 0-.6.3-.7.6l-2 13.1c0 .3.2.5.4.5H4l.8-4.9v.2c.1-.3.4-.6.7-.6H7c2.9 0 5.1-1.2 5.8-4.5v-.3c-.1 0-.1 0 0 0 .1-1.3-.1-2.1-.8-2.9"
      ></path>
      <path
        fill="#139AD6"
        d="M12.7 28.1v.3c-.7 3.4-2.9 4.5-5.8 4.5H5.4c-.3 0-.6.3-.7.6l-1 6.1c0 .2.1.4.4.4h2.6c.3 0 .6-.2.6-.5v-.1l.5-3.1v-.2c0-.3.3-.5.6-.5h.4c2.5 0 4.5-1 5-4 .2-1.2.1-2.3-.5-3-.1-.2-.3-.4-.6-.5"
      ></path>
      <path
        fill="#232C65"
        d="M12 27.8c-.1 0-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.4-.1-.8-.1-1.3-.1H6.2c-.1 0-.2 0-.3.1-.2.1-.3.3-.3.5l-.8 5.2v.2c.1-.3.4-.6.7-.6H7c2.9 0 5.1-1.2 5.8-4.5 0-.1 0-.2.1-.3-.2-.1-.3-.2-.5-.2-.3-.1-.3-.1-.4-.1"
      ></path>
    </svg>
  );
};

export const Visa = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64" height="64"
      id="visa-credit-card"
    >
      <g data-name="Visa credit card">
        <path
          fill="#273991"
          d="m12.77 32.28-1.69-8.17a2 2 0 0 0-2.2-1.5H1.06L1 23c6.08 1.46 10.1 5 11.77 9.28Zm5.68-9.49L13.51 35.4 13 33.5a19.77 19.77 0 0 0-7-7.66l4.51 15.41h5.33l7.94-18.46Zm7.38 0-3.15 18.5h5l3.16-18.5Zm33.09 0H55a2.5 2.5 0 0 0-2.64 1.54l-7.45 16.94h5.29s.86-2.28 1.06-2.78h6.45c.15.64.61 2.76.61 2.76H63Zm-6.21 11.92c.42-1.06 2-5.17 2-5.17s.42-1.06.67-1.76l.35 1.59 1.16 5.34ZM41 30.2c-1.75-.85-2.83-1.43-2.82-2.29s.91-1.59 2.88-1.59a9.08 9.08 0 0 1 3.77.71l.46.21.68-4a12.89 12.89 0 0 0-4.51-.77c-5 0-8.49 2.5-8.52 6.1 0 2.65 2.5 4.14 4.41 5S40 35.06 40 35.87c0 1.24-1.57 1.81-3 1.81a10.58 10.58 0 0 1-4.74-1l-.65-.3-.71 4.14a15.87 15.87 0 0 0 5.62 1c5.29 0 8.73-2.48 8.77-6.32-.01-2.08-1.35-3.68-4.29-5Z"
        ></path>
        <path
          fill="#f99f1b"
          d="M11.08 24.11a2 2 0 0 0-2.2-1.5H1.06L1 23c6.08 1.47 10.1 5 11.77 9.29Z"
        ></path>
      </g>
    </svg>
  );
};

export const MasterCard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64" height="64"
      id="mastercard"
    >
      <path
        fill="#FFB600"
        d="M63.5 32c0 10.4-8.4 18.9-18.9 18.9-10.4 0-18.9-8.5-18.9-18.9 0-10.4 8.4-18.9 18.8-18.9 10.6 0 19 8.5 19 18.9z"
      ></path>
      <path
        fill="#F7981D"
        d="M44.6 13.1c10.4 0 18.9 8.5 18.9 18.9 0 10.4-8.4 18.9-18.9 18.9-10.4 0-18.9-8.5-18.9-18.9"
      ></path>
      <path
        fill="#FF8500"
        d="M44.6 13.1c10.4 0 18.9 8.5 18.9 18.9 0 10.4-8.4 18.9-18.9 18.9"
      ></path>
      <path
        fill="#FF5050"
        d="M19.2 13.1C8.9 13.2.5 21.6.5 32c0 10.4 8.4 18.9 18.9 18.9 4.9 0 9.3-1.9 12.7-4.9.7-.6 1.3-1.3 1.9-2h-3.9c-.5-.6-1-1.3-1.4-1.9h6.7c.4-.6.8-1.3 1.1-2h-8.9c-.3-.6-.6-1.3-.8-2h10.4c.6-1.9 1-3.9 1-6 0-1.4-.2-2.7-.4-4H26.2c.1-.7.3-1.3.5-2h10.4c-.2-.7-.5-1.4-.8-2h-8.8c.3-.7.7-1.3 1.1-2h6.7c-.4-.7-.9-1.4-1.5-2h-3.7c.6-.7 1.2-1.3 1.9-1.9-3.3-3.1-7.8-4.9-12.7-4.9 0-.2 0-.2-.1-.2z"
      ></path>
      <path
        fill="#E52836"
        d="M.5 32c0 10.4 8.4 18.9 18.9 18.9 4.9 0 9.3-1.9 12.7-4.9.7-.6 1.3-1.3 1.9-2h-3.9c-.5-.6-1-1.3-1.4-1.9h6.7c.4-.6.8-1.3 1.1-2h-8.9c-.3-.6-.6-1.3-.8-2h10.4c.6-1.9 1-3.9 1-6 0-1.4-.2-2.7-.4-4H26.2c.1-.7.3-1.3.5-2h10.4c-.2-.7-.5-1.4-.8-2h-8.8c.3-.7.7-1.3 1.1-2h6.7c-.4-.7-.9-1.4-1.5-2h-3.7c.6-.7 1.2-1.3 1.9-1.9-3.3-3.1-7.8-4.9-12.7-4.9h-.1"
      ></path>
      <path
        fill="#CB2026"
        d="M19.4 50.9c4.9 0 9.3-1.9 12.7-4.9.7-.6 1.3-1.3 1.9-2h-3.9c-.5-.6-1-1.3-1.4-1.9h6.7c.4-.6.8-1.3 1.1-2h-8.9c-.3-.6-.6-1.3-.8-2h10.4c.6-1.9 1-3.9 1-6 0-1.4-.2-2.7-.4-4H26.2c.1-.7.3-1.3.5-2h10.4c-.2-.7-.5-1.4-.8-2h-8.8c.3-.7.7-1.3 1.1-2h6.7c-.4-.7-.9-1.4-1.5-2h-3.7c.6-.7 1.2-1.3 1.9-1.9-3.3-3.1-7.8-4.9-12.7-4.9h-.1"
      ></path>
      <path
        fill="#FFF"
        d="m26.1 36.8.3-1.7c-.1 0-.3.1-.5.1-.7 0-.8-.4-.7-.6l.6-3.5h1.1l.3-1.9h-1l.2-1.2h-2s-1.2 6.6-1.2 7.4c0 1.2.7 1.7 1.6 1.7.6 0 1.1-.2 1.3-.3zM26.8 33.6c0 2.8 1.9 3.5 3.5 3.5 1.5 0 2.1-.3 2.1-.3l.4-1.9s-1.1.5-2.1.5c-2.2 0-1.8-1.6-1.8-1.6H33s.3-1.3.3-1.8c0-1.3-.7-2.9-2.9-2.9-2.1-.2-3.6 2-3.6 4.5zm3.5-2.9c1.1 0 .9 1.3.9 1.4H29c0-.1.2-1.4 1.3-1.4zM43 36.8l.4-2.2s-1 .5-1.7.5c-1.4 0-2-1.1-2-2.3 0-2.4 1.2-3.7 2.6-3.7 1 0 1.8.6 1.8.6l.3-2.1s-1.2-.5-2.3-.5c-2.3 0-4.6 2-4.6 5.8 0 2.5 1.2 4.2 3.6 4.2.8 0 1.9-.3 1.9-.3zM15.1 28.9c-1.4 0-2.4.4-2.4.4l-.3 1.7s.9-.4 2.2-.4c.7 0 1.3.1 1.3.7 0 .4-.1.5-.1.5h-.9c-1.7 0-3.6.7-3.6 3 0 1.8 1.2 2.2 1.9 2.2 1.4 0 2-.9 2.1-.9l-.1.8H17l.8-5.5c0-2.4-2-2.5-2.7-2.5zm.4 4.5c0 .3-.2 1.9-1.4 1.9-.6 0-.8-.5-.8-.8 0-.5.3-1.2 1.8-1.2.3.1.4.1.4.1zM19.7 37c.5 0 3 .1 3-2.6 0-2.5-2.4-2-2.4-3 0-.5.4-.7 1.1-.7.3 0 1.4.1 1.4.1l.3-1.8s-.7-.2-1.9-.2c-1.5 0-3 .6-3 2.6 0 2.3 2.5 2.1 2.5 3 0 .6-.7.7-1.2.7-.9 0-1.8-.3-1.8-.3l-.3 1.8c.1.2.6.4 2.3.4zM59.6 27.3l-.4 2.7s-.8-1-1.9-1c-1.8 0-3.4 2.2-3.4 4.8 0 1.6.8 3.3 2.5 3.3 1.2 0 1.9-.8 1.9-.8l-.1.7h2l1.5-9.6-2.1-.1zm-.9 5.3c0 1.1-.5 2.5-1.6 2.5-.7 0-1.1-.6-1.1-1.6 0-1.6.7-2.6 1.6-2.6.7 0 1.1.5 1.1 1.7zM4.2 36.9l1.2-7.2.2 7.2H7l2.6-7.2-1.1 7.2h2.1l1.6-9.6H8.9l-2 5.9-.1-5.9H3.9l-1.6 9.6h1.9zM35.2 36.9c.6-3.3.7-6 2.1-5.5.2-1.3.5-1.8.7-2.3h-.4c-.9 0-1.6 1.2-1.6 1.2l.2-1.1h-1.9L33 37h2.2zM47.6 28.9c-1.4 0-2.4.4-2.4.4l-.3 1.7s.9-.4 2.2-.4c.7 0 1.3.1 1.3.7 0 .4-.1.5-.1.5h-.9c-1.7 0-3.6.7-3.6 3 0 1.8 1.2 2.2 1.9 2.2 1.4 0 2-.9 2.1-.9l-.1.8h1.8l.8-5.5c.1-2.4-2-2.5-2.7-2.5zm.5 4.5c0 .3-.2 1.9-1.4 1.9-.6 0-.8-.5-.8-.8 0-.5.3-1.2 1.8-1.2.3.1.3.1.4.1zM52 36.9c.6-3.3.7-6 2.1-5.5.2-1.3.5-1.8.7-2.3h-.4c-.9 0-1.6 1.2-1.6 1.2l.2-1.1h-1.9L49.8 37H52z"
      ></path>
      <path
        fill="#DCE5E5"
        d="M23 35.4c0 1.2.7 1.7 1.6 1.7.7 0 1.3-.2 1.5-.3l.3-1.7c-.1 0-.3.1-.5.1-.7 0-.8-.4-.7-.6l.6-3.5h1.1l.3-1.9h-1l.2-1.2M27.8 33.6c0 2.8.9 3.5 2.5 3.5 1.5 0 2.1-.3 2.1-.3l.4-1.9s-1.1.5-2.1.5c-2.2 0-1.8-1.6-1.8-1.6H33s.3-1.3.3-1.8c0-1.3-.7-2.9-2.9-2.9-2.1-.2-2.6 2-2.6 4.5zm2.5-2.9c1.1 0 1.3 1.3 1.3 1.4H29c0-.1.2-1.4 1.3-1.4zM43 36.8l.4-2.2s-1 .5-1.7.5c-1.4 0-2-1.1-2-2.3 0-2.4 1.2-3.7 2.6-3.7 1 0 1.8.6 1.8.6l.3-2.1s-1.2-.5-2.3-.5c-2.3 0-3.6 2-3.6 5.8 0 2.5.2 4.2 2.6 4.2.8 0 1.9-.3 1.9-.3zM12.4 31.1s.9-.4 2.2-.4c.7 0 1.3.1 1.3.7 0 .4-.1.5-.1.5h-.9c-1.7 0-3.6.7-3.6 3 0 1.8 1.2 2.2 1.9 2.2 1.4 0 2-.9 2.1-.9l-.1.8H17l.8-5.5c0-2.3-2-2.4-2.8-2.4m1.5 4.3c0 .3-1.2 1.9-2.4 1.9-.6 0-.8-.5-.8-.8 0-.5.3-1.2 1.8-1.2.3.1 1.4.1 1.4.1zM17.5 36.8s.6.2 2.3.2c.5 0 3 .1 3-2.6 0-2.5-2.4-2-2.4-3 0-.5.4-.7 1.1-.7.3 0 1.4.1 1.4.1l.3-1.8s-.7-.2-1.9-.2c-1.5 0-2 .6-2 2.6 0 2.3 1.5 2.1 1.5 3 0 .6-.7.7-1.2.7M59.2 30s-.8-1-1.9-1c-1.8 0-2.4 2.2-2.4 4.8 0 1.6-.2 3.3 1.5 3.3 1.2 0 1.9-.8 1.9-.8l-.1.7h2l1.5-9.6m-2.6 5.2c0 1.1-.9 2.5-2 2.5-.7 0-1.1-.6-1.1-1.6 0-1.6.7-2.6 1.6-2.6.7 0 1.5.5 1.5 1.7zM4.2 36.9l1.2-7.2.2 7.2H7l2.6-7.2-1.1 7.2h2.1l1.6-9.6H9.7l-2.8 5.9-.1-5.9H5.7l-3.4 9.6h1.9zM33.1 36.9h2.1c.6-3.3.7-6 2.1-5.5.2-1.3.5-1.8.7-2.3h-.4c-.9 0-1.6 1.2-1.6 1.2l.2-1.1M44.9 31.1s.9-.4 2.2-.4c.7 0 1.3.1 1.3.7 0 .4-.1.5-.1.5h-.9c-1.7 0-3.6.7-3.6 3 0 1.8 1.2 2.2 1.9 2.2 1.4 0 2-.9 2.1-.9l-.1.8h1.8l.8-5.5c0-2.3-2-2.4-2.8-2.4m1.5 4.3c0 .3-1.2 1.9-2.4 1.9-.6 0-.8-.5-.8-.8 0-.5.3-1.2 1.8-1.2.4.1 1.4.1 1.4.1zM49.9 36.9H52c.6-3.3.7-6 2.1-5.5.2-1.3.5-1.8.7-2.3h-.4c-.9 0-1.6 1.2-1.6 1.2l.2-1.1"
      ></path>
    </svg>
  );
};
